import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {TextInput} from '../../components/TextInput/index.js';
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {createTimeslot, updateTimeslot, updateRecord, createDateTime,  getMedicsBySearch, getSolicitorsBySearch, resendEmails, getMedicRecords,getProfileDiscover, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';
import profileURL from "../../images/default-profile.png";
import plusIcon from '../../images/plus-icon.png';
import xButton from '../../images/x-button.png';
import filterBookings from '../../utils/filterBookings';

export const Timeslots = () => {
  const [user, setUser] = useState({});
  const [results, setResults] = useState([]);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [editReferralOpen,setEditReferralOpen] = useState(false);
  const navigate = useNavigate();
  const [chosenTimes, setChosenTimes] = useState([]);


  const [piab, setPiab] = useState('')
  const [claimantName, setClaimantName] = useState('')
  const [claimantPhone, setClaimantPhone] = useState('')
  const [claimantAddress, setClaimantAddress] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('')
  const [claimantEmail, setClaimantEmail] = useState('')
  const [claimantDOB, setClaimantDOB] = useState('')
  const [incidentDate, setIncidentDate] = useState('')
  const newDateRef = useRef(null);
  const [clinicUpdate, setClinicUpdate] = useState(false)
  const [dateExisting, setDateExisting] = useState(new Date());
  const [dateNew, setDateNew] = useState(new Date());
  const [dateTimeLocationNew, setDateTimeLocationNew ] = useState('');
  const [dateTimeLocationExisting, setDateTimeLocationExisting ] = useState('');
  const [ldta, setLdta] = useState('')
  const [solicitorName, setSolicitorName] = useState('')
  const [solicitorEmail, setSolicitorEmail] = useState('')
  const [specialNoteToSolicitor, setSpecialNoteToSolicitor] = useState('')
  const [specialNoteToDoctor, setSpecialNoteToDoctor] = useState('')
  const [medicType, setMedicType] = useState('')
  const [solicitorReference, setSolicitorReference] = useState('')
  const [backupSolicitorEmail, setBackupSolicitorEmail] = useState('')
  const [clinicLocation, setClinicLocation] = useState('')
  const [clinicAddress, setClinicAddress] = useState('')
  const [expertEmail, setExpertEmail] = useState('')
  const [medicalExpert, setMedicalExpert] = useState('')
  const [medicId, setMedicId] = useState('')
  const [expertType, setExpertType] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [recordId, setRecordId] = useState(null)
  const [recordIndex, setRecordIndex] = useState(null)
  const [sendClientEmail, setSendClientEmail] = useState(true)
  const [sendMedicEmail, setSendMedicEmail] = useState(true)
  const [sendPiabEmail, setSendPiabEmail] = useState(true)
  const [sendSolicitorEmail, setSendSolicitorEmail] = useState(true)
  const [oldDocuments, setOldDocuments] = useState([])
  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(true);
  const [dropdownTime, setDropdownTime] = useState('');
  const [editDoctorClinics, setEditDoctorClinics] = useState([]);
  const [originalClinics, setOriginalClinics] = useState([]);
  const [doctors, setDoctors] = useState([])
  const [solicitors, setSolicitors] = useState([])
  const [dateTimeLocation, setDateTimeLocation] = useState('');
  const [editDoctorId, setEditDoctorId] = useState(null);
  const [newDate, setNewDate] = useState(new Date());
  const [filterStartDate, setFilterStartDate] = useState(null)
  const [filterEndDate, setFilterEndDate] = useState(null)
  const filterStartDateRef = useRef(null)
  const filterEndDateRef = useRef(null)

  const [records, setRecords] = useState([]);

  const resendReferralEmails = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    await setLoadingForm(true)
    const resendRes = await resendEmails({formId:recordId, sendMedicEmail, sendSolicitorEmail, sendClientEmail, sendPiabEmail},token);
    if(resendRes.data.success){
      alert('Emails Sent');
    }else{
      alert('Error sending emails. Some emails may not have been sent:'+resendRes.data.error);
    }

    await setLoadingForm(false)
  }

  const searchDoctors = async(search, token)=>{
    const doctors = await getMedicsBySearch({token,search});
    if(doctors.data.success){
      setDoctors(doctors.data.medics.map((doctor,doctorIndex)=>{
        return {...doctor}
      }));
    }
  }

  const searchSolicitors = async(search, token)=>{
    if(search.length == 0) return;
    const solicitors = await getSolicitorsBySearch({token,search});
    if(solicitors.data.success){
      setSolicitors(solicitors.data.solicitors.map((solicitor,solicitorIndex)=>{
        return {...solicitor}
      }));
    }
  }

  const updateAppointment = async()=>{
    //setLoading(true);
    let formData = new FormData();
    for(let index = 0; index < files.length;index++){
      formData.append('file'+(index+1), files[index])
    }

    await setLoadingForm(true)

    formData.append('piab', piab)
    formData.append('claimantName', claimantName)
    formData.append('claimantPhone', claimantPhone)
    formData.append('claimantAddress', claimantAddress)
    formData.append('appointmentTime', appointmentTime)
    formData.append('claimantEmail', claimantEmail)
    formData.append('claimantDOB', claimantDOB)
    formData.append('incidentDate', incidentDate)
    formData.append('ldta', ldta)
    formData.append('solicitorName', solicitorName)
    formData.append('solicitorEmail', solicitorEmail)
    formData.append('specialNoteToSolicitor', specialNoteToSolicitor)
    formData.append('specialNoteToDoctor', specialNoteToDoctor)
    formData.append('medicType', medicType)
    formData.append('solicitorReference', solicitorReference)
    formData.append('backupSolicitorEmail', backupSolicitorEmail)
    formData.append('clinicLocation', clinicLocation)
    formData.append('clinicAddress', clinicAddress)
    formData.append('expertEmail', expertEmail)
    formData.append('medicalExpert', medicalExpert)
    formData.append('expertType', expertType)
    formData.append('recordId',  recordId)
    formData.append('appointmentDate', appointmentDate)
    formData.append('medicId', medicId)
    formData.append('oldDocuments', oldDocuments.join(','))

    console.log(recordId);

    const cookies = new Cookies();
    const token = await cookies.get('token');

    const uploadResult = await updateRecord(formData,token);
    if(uploadResult.data.success){
      setFiles([])
      setOldDocuments(uploadResult.data.record.documentURLs.split(','))
      alert('Appointment Saved');

      let recordsTmp = records;
      recordsTmp[recordIndex] = uploadResult.data.record;
      setRecords(recordsTmp)
    }else{
      console.log(uploadResult.data)
      alert("Error saving. Please try again.");
    }
    await setLoadingForm(false)
    setLoading(false);
  }


  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
        setOriginalClinics(userDetails.data.myClinics);
        setEditDoctorClinics(userDetails.data.myClinics.map((m)=>{ return m.name }));
        setChosenTimes(userDetails.data.timeslots)

        console.log(userDetails.data.timeslots)
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getMedicRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records);
        console.log('RECORDS');
        console.log(recordDetails.data.records)
      }else{
        console.log(recordDetails.data)
        alert("Error getting your records.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateAppointment();}} />
    )
  }

  const renderResendEmailsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Resend Emails" onClick={(event)=>{resendReferralEmails();}} />
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }

  const formatDateObject = (date)=>{
    return (new Date(date).getDate())+"/"+(new Date(date).getMonth()+1)+"/"+(new Date(date).getFullYear())
  }

  const renderNewOrder = ()=>{
    if(!editReferralOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditReferralOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>View Referral</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Edit Case</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                    PIAB reference number: {piab}<br/><br/>
                    Claimant Name: {claimantName}<br/><br/>
                    Claimant Phone: {claimantPhone}<br/><br/>
                    Claimant Address: {claimantAddress}<br/><br/>
                    Medic Type Needed: {medicType}<br/><br/>

                  </div>
                  <div className={styles.cardColumn}>
                    Claimant Email: {claimantEmail}<br/><br/>
                    Claimant DOB: {claimantDOB}<br/><br/>
                    Incident Date: {incidentDate}<br/><br/>
                    LDTA: {ldta}<br/><br/>
                    Special Note to Medic: {specialNoteToDoctor}<br/><br/>
                  </div>
                </div>
                <br/><br/>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                <br/><br/>

                  </div>
                  <div className={styles.cardColumn}>
                </div>
                </div>

                {
                  oldDocuments.filter((item)=>{ return item != ''}).map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        <a href={file}>Existing File {fileIndex+1}</a>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Medical Apt. Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>

                  Expert Type: {expertType}

                  </div>

                  <div className={styles.cardColumn}>

                  Appointment Date: {appointmentDate}

                <br/><br/>

                Available Times: {dropdownTime}
                <br/><br/>

                  <div style={{marginLeft:"0vw"}}>
                    Appointment Time:{appointmentTime}
                  </div>

                  </div>

                </div><br/><br/>

                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                    Clinic Address: {clinicAddress}
                  </div>

                  <div className={styles.cardColumn}>
                    Expert Email: {expertEmail}
                  </div>
                </div>

              </div>
            </div>

          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const getDate = (date)=>{
    const dateTime = new Date(date)
    return (dateTime.getDate())+"/"+(dateTime.getMonth()+1)+"/"+(dateTime.getFullYear())+" "+dateTime.getHours()+":"+dateTime.getMinutes()
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewOrder()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Timeslots"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.name}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px", height:"85vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardHeaderTitle}>
                      <img src={require('../../images/timeslot-icon.png')} className={styles.cardHeaderIcon} />
                      My Timeslots
                    </div>
                    <div className={styles.searchContainer}>
                      <TextInput  placeholder="Search From Date*"  type="date" ref={filterStartDateRef} onChange={(value)=>{setFilterStartDate(value.getTime())}} initialValue={null}/>
                      <TextInput  placeholder="Search To Date*" type="date" ref={filterEndDateRef} onChange={(value)=>{setFilterEndDate(value.getTime())}} initialValue={null}/>
                    </div>
                  </div>

                  <div className={styles.timeslotsContent}>
                  <div className={styles.locationsForm}>
                    <div className={styles.locationsListColumn}>
                  <TextInput placeholder="Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                    setDateTimeLocation(value)
                  }} initialValue={dateTimeLocation}/>
                  <div className={styles.dateTimeContainer}>
                    <TextInput placeholder="Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setNewDate(value)}} initialValue={newDate}/>
                  </div>
                  <br/><br/>
                  <TextInput style={{marginLeft:'0px'}} large={true} type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                    let timesTmp = chosenTimes;
                    const cookies = new Cookies();
                    const token = await cookies.get('token');
                    const res = await createTimeslot({token,timeslots:[{startTime:new Date(newDate).getTime(),endTime:0,clinicId:originalClinics.filter((e)=>{return e.name == dateTimeLocation})[0]?.id}]});

                    await setChosenTimes(res.data.timeslots);
                    await setClinicUpdate(!clinicUpdate);
                  }} />
                  <br/><br/>
                  <div className={styles.line}></div><br/>
                  Duplicate A Location and Date Schedule<br/>
                  <TextInput placeholder="Chosen Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                    setDateTimeLocationExisting(value)
                  }} initialValue={dateTimeLocationExisting}/>
                  <TextInput placeholder="New Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                    setDateTimeLocationNew(value)
                  }} initialValue={dateTimeLocationNew}/>
                  <br/><br/>
                  <TextInput placeholder="Existing Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setDateExisting(value)}} initialValue={dateExisting}/>
                  <TextInput placeholder="New Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setDateNew(value)}} initialValue={dateNew}/>
                  <br/><br/>
                  <TextInput style={{marginLeft:'0px'}} large={true} type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                    let cloneTimes = chosenTimes.filter((time)=>{ return (time.date == formatDateObject(dateExisting) && time.location == dateTimeLocationExisting) });
                    let timesTmp = chosenTimes;
                    const cookies = new Cookies();
                    const token = await cookies.get('token');

                    for(let i = 0;i < cloneTimes.length;i++){
                      const res = await createDateTime({token,date:formatDateObject(dateNew),time:cloneTimes[i].time,doctorId:editDoctorId, location:dateTimeLocationNew});
                      timesTmp.push(res.data.newDateTime);
                    }

                    await setChosenTimes(timesTmp);
                    await setClinicUpdate(!clinicUpdate);
                  }} />
                  </div>
                  <div className={styles.locationsEntryColumn}>
                    {
                      filterBookings(chosenTimes || [],{filterStartDate,filterEndDate}).map((time,timeIndex)=>{
                        return(
                          <div className={styles.chosenTime}>
                            {time['m5_clinic']?.name} {getDate(time.startTime)} <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                              let timesTmp = chosenTimes;
                              timesTmp.splice(timeIndex,1);
                              const cookies = new Cookies();
                              const token = await cookies.get('token');
                              const res = await updateTimeslot({token,timeslotId:time.id});
                              console.log(res.data)
                              await setChosenTimes(res.data.timeslots);
                              await setClinicUpdate(!clinicUpdate);
                            }} className={styles.removeFileButton} />
                          </div>
                        )
                      })
                    }
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
