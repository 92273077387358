const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const MONTHS = [
  {name:'January',short:"Jan",color:'#2A7EFF'},
  {name:'February',short:"Feb",color:'#00E976'},
  {name:'March',short:"Mar",color:'#85FF15'},
  {name:'April',short:"Apr",color:'#DF0051'},
  {name:'May',short:"May",color:'#FF6235'},
  {name:'June',short:"Jun",color:'#001994'},
  {name:'July',short:"Jul",color:'#35D4FF'},
  {name:'August',short:"Aug",color:'#7100D4'},
  {name:'September',short:"Sept",color:'#00554F'},
  {name:'October',short:"Oct",color:'#C02AFF'},
  {name:'November',short:"Nov",color:'#DF0000'},
  {name:'December',short:"Dec",color:'#986AFF'},
]

const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export {MONTHS, SHORT_MONTHS, TIMES}
