import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { ClipLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import TimePicker from "rc-time-picker";
import {setMedicProfileImageService,updateMedic,sendConfirmationEmails, sendReminderEmails, updateRecord, updateTimes, createDateTime, updateClinics, getMedicsBySearch, getSolicitorsBySearch, resendEmails, getMedicTypes,getMedicRecords,getProfileDiscover, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';
import profileURL from "../../images/default-profile.png";
import plusIcon from '../../images/plus-icon.png';


const COUNTIES = [
    'Antrim',
    'Armagh',
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Derry',
    'Donegal',
    'Down',
    'Dublin',
    'Fermanagh',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Tyrone',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow'
]


export const Clinics = () => {
  const [user, setUser] = useState({});
  const [results, setResults] = useState([]);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const navigate = useNavigate();

  const [clinicUpdate, setClinicUpdate] = useState(false)
  const [newClinic, setNewClinic] = useState('')
  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(true);
  const [editDoctorClinics, setEditDoctorClinics] = useState([]);
  const [editDoctorBio, setEditDoctorBio] = useState('')
  const [rumuEmail, setRumuEmail] = useState('')
  const [editDoctorProfessionalMembership, setEditDoctorProfessionalMembership] = useState('')
  const [editDoctorConditions, setEditDoctorConditions] = useState([])
  const [editDoctorCounties, setEditDoctorCounties] = useState([])
  const [editDoctorCategories, setEditDoctorCategories] = useState([]);
  const [editDoctorSpecialties, setEditDoctorSpecialties] = useState([]);
  const [anonymous, setAnonymous] = useState(1)
  const [file, setFile] = useState(null)
  const [editDoctorCountiesText, setEditDoctorCountiesText] = useState(COUNTIES[0])
  const [editDoctorConditionsText, setEditDoctorConditionsText] = useState('')
  const [CATEGORIES, setCategories] = useState([])
  const [SPECIALTIES, setSpecialties] = useState([])

  const [confirmationNeeded, setConfirmationNeeded] = useState(false)
  const [referralNeeded, setReferralNeeded] = useState(false)

  const [chosenTimes, setChosenTimes] = useState([]);

  const [editDoctorCategoriesText, setEditDoctorCategoriesText] = useState(null);
  const [editDoctorSpecialtiesText, setEditDoctorSpecialtiesText] = useState(null);

  const [editDoctorProfileImage, setEditDoctorProfileImage] = useState('');

  const [records, setRecords] = useState([]);


  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
        setEditDoctorClinics((userDetails.data.clinics || '').split('[****SEP****]'))
        setEditDoctorCategories((userDetails.data.dataValues.rumuCategories || '').split('[****SEP****]'))
        setEditDoctorSpecialties((userDetails.data.dataValues.rumuSpecialties || '').split('[****SEP****]'))
        setEditDoctorConditions((userDetails.data.dataValues.rumuConditions || '').split('[****SEP****]'))
        setRumuEmail(userDetails.data.dataValues.rumuEmail || '')
        setChosenTimes(userDetails.data.timeslots)
        setEditDoctorBio(userDetails.data.dataValues.rumuBio || '')
        setEditDoctorProfessionalMembership(userDetails.data.dataValues.professionalMembership || '')
        setConfirmationNeeded(userDetails.data.dataValues.confirmationNeeded || false)
        setReferralNeeded(userDetails.data.dataValues.referralNeeded || false)
        setAnonymous(userDetails.data.dataValues.anonymous == 1 ? 1:0)
        setEditDoctorCounties((userDetails.data.dataValues.rumuCounty || '').split('[****SEP****]'))
        setEditDoctorProfileImage(userDetails.data.dataValues.profile)
        console.log(userDetails.data)
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getMedicRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records);
        console.log('RECORDS');
        console.log(recordDetails.data.records)
      }else{
        console.log(recordDetails.data)
        alert("Error getting your records.");
      }

      const medicTypes = await getMedicTypes({token})

      if(medicTypes.data.success){
        setCategories(medicTypes.data.categories)
        setSpecialties(medicTypes.data.specialties)
        setEditDoctorSpecialtiesText(medicTypes.data.specialties[0] || null)
        setEditDoctorCategoriesText(medicTypes.data.categories[0] || null)
      }else{
        console.log(medicTypes.data)
      }



      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <ClipLoader  size={50} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{}} />
    )
  }

  const renderResendEmailsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <ClipLoader  size={50} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Resend Emails" onClick={(event)=>{}} />
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <ClipLoader  size={30} color={"#147d61"} />
        </div>
      </div>
    )
  }



  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  const formatDateObject = (date)=>{
    return (new Date(date).getDate())+"/"+(new Date(date).getMonth()+1)+"/"+(new Date(date).getFullYear())
  }

  const downloadCSV = ()=>{
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Name,Address,Phone,Email,Price (€)" + "\r\n";
    results.map((result)=>{return([result.orderDate,result.customerName,result.customerAddress,result.customerPhone,result.customerEmail,result.price])}).forEach((rowArray)=>{
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  if(token){
    return(
      <div className="global-container">
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Clinics"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.name}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px", height:"85vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                    My Profile
                    <div className={styles.searchContainer}>
                    </div>
                  </div>

                  <div className={styles.timeslotsContent}>
                    <div className={styles.locationsForm}>
                      <div className={styles.locationsListColumn}>
                        <TextInput placeholder="New Clinic Location*" id="abc" type="form" onChange={(value)=>{setNewClinic(value)}} initialValue={newClinic}/>
                        <br/><br/>
                        <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                          let clinicsTmp = editDoctorClinics;
                          clinicsTmp.push(newClinic);
                          const cookies = new Cookies();
                          const token = await cookies.get('token');
                          await updateClinics({token,clinics:clinicsTmp});
                          await setEditDoctorClinics(clinicsTmp);
                          await setClinicUpdate(!clinicUpdate);
                        }} />
                      </div>
                      <div className={styles.locationsEntryColumn}>
                        {
                          editDoctorClinics.map((clinic, clinicIndex)=>{
                            return(
                              <div>
                                {clinic} <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                                  let clinicsTmp = editDoctorClinics;
                                  clinicsTmp.splice(clinicIndex,1);
                                  const cookies = new Cookies();
                                  const token = await cookies.get('token');
                                  await updateClinics({token,clinics:clinicsTmp});
                                  await setEditDoctorClinics(clinicsTmp);
                                  await setClinicUpdate(!clinicUpdate);
                                }} className={styles.removeFileButton} />
                              </div>
                            )
                          })
                        }
                      </div>

                    </div>
                  </div>

                  <div className={styles.bioDetails} style={{textAlign:'left',paddingLeft:'40px'}}>
                  <div className={styles.subheader}>My Details</div>
                  <div className={styles.locationsForm}>
                    <div className={styles.locationsListColumn}>
                      <TextInput placeholder="Medic Bio*" disabled={(anonymous)} type="form-text" onChange={(value)=>{setEditDoctorBio(value)}} initialValue={editDoctorBio}/>
                      <br/><br/>
                      <TextInput placeholder="Medic Professional Memberships*" disabled={(anonymous)} type="form-text" onChange={(value)=>{setEditDoctorProfessionalMembership(value)}} initialValue={editDoctorProfessionalMembership}/>
                      <br/><br/>
                      <TextInput placeholder="Rumu Contact Email*" disabled={false} type="form-text" onChange={(value)=>{setRumuEmail(value)}} initialValue={rumuEmail}/>
                      <br/><br/>
                      <TextInput placeholder="Treated Conditions*" type="form-text" options={COUNTIES} onChange={(value)=>{setEditDoctorConditionsText(value)}} initialValue={editDoctorConditionsText}/>
                      <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                        let clinicsTmp = editDoctorConditions;
                        clinicsTmp.push(editDoctorConditionsText);
                        await setEditDoctorConditions(clinicsTmp);
                        await setEditDoctorConditionsText('')
                        await setClinicUpdate(!clinicUpdate);
                      }} /><br/><br/>
                      {
                        editDoctorConditions.filter(c => c.length).map((clinic, clinicIndex)=>{
                          return(
                            <div style={{paddingTop:"10px"}}>
                              {clinic}
                              <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                                let clinicsTmp = editDoctorConditions;
                                clinicsTmp.splice(clinicIndex,1);
                                await setEditDoctorConditions(clinicsTmp);
                                await setClinicUpdate(!clinicUpdate);
                              }} className={styles.removeFileButton} />
                            </div>
                          )
                        })
                      }
                      <br/><br/>
                      <input type="checkbox" id="checkbox1" name="checkbox1" checked={confirmationNeeded} onChange={(event)=>{setConfirmationNeeded(!confirmationNeeded)}}></input>
                      <span style={{paddingRight:"0px"}}> Request Confirmation For Appointments Made By Patients</span>
                      <br/><br/>

                      <input type="checkbox" id="checkbox1" name="checkbox1" checked={referralNeeded} onChange={(event)=>{setReferralNeeded(!referralNeeded)}}></input>
                      <span style={{paddingRight:"0px"}}> Require Doctor Referral</span>
                      <br/><br/>

                      <img style={{marginTop:'20px',width:'40px',height:'40px',borderRadius:'50px'}} src={editDoctorProfileImage || require('../../images/medic-profile.png')} />
                      <label for="logoInput">
                          <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Profile Image" onClick={(event)=>{}} />
                      </label>

                    </div>
                    <div className={styles.locationsListColumn}>
                    <TextInput placeholder="County*" type="dropdown" options={COUNTIES} onChange={(value)=>{setEditDoctorCountiesText(value)}} initialValue={editDoctorCountiesText}/>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                      let clinicsTmp = editDoctorCounties;
                      clinicsTmp.push(editDoctorCountiesText);
                      await setEditDoctorCounties(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} /><br/><br/>
                    {
                      editDoctorCounties.map((clinic, clinicIndex)=>{
                        return(
                          <div style={{paddingTop:"10px"}}>
                            {clinic}
                            <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                              let clinicsTmp = editDoctorCounties;
                              clinicsTmp.splice(clinicIndex,1);
                              await setEditDoctorCounties(clinicsTmp);
                              await setClinicUpdate(!clinicUpdate);
                            }} className={styles.removeFileButton} />
                          </div>
                        )
                      })
                    }

                    <br/>
                    <TextInput placeholder="Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })} onChange={(value)=>{setEditDoctorCategoriesText(value)}} initialValue={editDoctorCategoriesText}/>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                      let clinicsTmp = editDoctorCategories;
                      clinicsTmp.push(editDoctorCategoriesText);
                      await setEditDoctorCategories(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} /><br/><br/>
                    {
                      editDoctorCategories.map((clinic, clinicIndex)=>{
                        return(
                          <div style={{paddingTop:"10px"}}>
                            {clinic}
                            <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                              let clinicsTmp = editDoctorCategories;
                              clinicsTmp.splice(clinicIndex,1);
                              await setEditDoctorCategories(clinicsTmp);
                              await setClinicUpdate(!clinicUpdate);
                            }} className={styles.removeFileButton} />
                          </div>
                        )
                      })
                    }
                    <br/><br/>
                    <TextInput placeholder="Specialty*" type="dropdown" options={SPECIALTIES.map((s,si)=>{ return s.name })} onChange={(value)=>{setEditDoctorSpecialtiesText(value)}} initialValue={editDoctorSpecialtiesText}/>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                      let clinicsTmp = editDoctorSpecialties;
                      clinicsTmp.push(editDoctorSpecialtiesText);
                      await setEditDoctorSpecialties([...clinicsTmp]);
                      await setClinicUpdate(!clinicUpdate);
                    }} /><br/><br/>
                    {
                      editDoctorSpecialties.map((clinic, clinicIndex)=>{
                        return(
                          <div style={{paddingTop:"10px"}}>
                            {clinic}
                            <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                              let clinicsTmp = editDoctorSpecialties;
                              clinicsTmp.splice(clinicIndex,1);
                              await setEditDoctorSpecialties(clinicsTmp);
                              await setClinicUpdate(!clinicUpdate);
                            }} className={styles.removeFileButton} />
                          </div>
                        )
                      })
                    }
                    <br/><br/>

                    <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{
                      setFile(event.target.files[0])
                      let selectedFile = event.target.files[0];
                      let reader = new FileReader();

                      reader.onload = function(event) {
                        setEditDoctorProfileImage(event.target.result)
                      };

                      reader.readAsDataURL(selectedFile);
                    }} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />

                    <br/><br/>
                    <TextInput type="submit-input" style={{marginLeft:'0px'}} icon={plusIcon} placeholder="Save" onClick={async(event)=>{
                      await setLoading(true)
                      let formData = new FormData();

                      //formData.append('specialties', editDoctorSpecialties.join('[****SEP****]'))
                      //formData.append('categories', editDoctorCategories.join('[****SEP****]'))
                      formData.append('county', editDoctorCounties.join('[****SEP****]'))
                      formData.append('confirmationNeeded', confirmationNeeded?1:0)
                      formData.append('rumuEmail', rumuEmail)
                      formData.append('referralNeeded', referralNeeded?1:0)
                      formData.append('specialties', editDoctorSpecialties.join('[****SEP****]'))
                      formData.append('categories', editDoctorCategories.join('[****SEP****]'))
                      formData.append('conditions', editDoctorConditions.join('[****SEP****]'))
                      formData.append('bio', editDoctorBio)
                      formData.append('professionalMembership', editDoctorProfessionalMembership)
                      formData.append('file', file)

                      const uploadResult = await updateMedic(formData,token);
                      if(uploadResult.data.success){
                        alert('Bio Saved');
                      }else{
                        alert("Error uploading. Please try again.");
                      }
                      setLoading(false);
                    }} />

                  </div>
                  </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
