const DELAYED_PAYMENT_TIME = (48*60*60*1000);

const isExpired = (time)=>{
  return time < (new Date().getTime() - DELAYED_PAYMENT_TIME)
}

const getPaymentStatus = (booking)=>{
  if(booking.paid){
    return "Paid"
  }else if(isExpired(booking['m5_timeslots'] && booking['m5_timeslots'].startTime)){
    return "Delayed Payment"
  }

  return "Not Paid"
}

export default getPaymentStatus
