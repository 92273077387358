import { useCallback, useState, useEffect,useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {setSocialToken, getUserDetailsService, getCompany,  uploadCompanyLogo} from '../../services/';
import { ColorPicker } from 'material-ui-color';
import styles from './styles.module.scss';

import InstagramLogin from 'react-instagram-login';

import logoDark from "../../images/logo-dark.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import locationIcon from '../../images/route-icon.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import editIcon from '../../images/edit-icon.png';
import plusIcon from '../../images/plus-icon.png';
import employeeIconPink from '../../images/employee-icon-pink.png';
import xButton from '../../images/x-button.png';
import bulkUploadIcon from '../../images/bulk-upload-icon.png';
import noProducts from '../../images/no-products-graphic.png';
import removeIcon from '../../images/remove-icon.png';
import storeIcon from '../../images/store-design.png';
import companyLogo from '../../images/my-company-logo.png';
import shoppingIcon from '../../images/shopping-icon.png';
import hashtagIcon from '../../images/hashtag-icon.png';
import socialIcon from '../../images/social-icon.png';



export const Confirm = () => {

    const [user, setUser] = useState({});
    const [editEmployee,setEditEmployee] = useState({});
    const [products, setProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(true);
    const [loadingForm,setLoadingForm] = useState(false);
    const [newInputWindowOpen,setNewInputWindowOpen] = useState(false);
    const [editInputWindowOpen,setEditInputWindowOpen] = useState(false);
    const [token,setToken] = useState(null);
    const [colourCodeMain, updateColourCodeMain] = useState('');
    const [colourCodeSecond, updateColourCodeSecond] = useState('');
    const [colourCodeButton, updateColourCodeButton] = useState('');
    const [newInputName, setNewInputName] = useState('');
    const [newInputType, setNewInputType] = useState('Text');
    const [newInputScreen, setNewInputScreen] = useState('');
    const [newInputMandatory, setNewInputMandatory] = useState(false);
    const [editInputName, setEditInputName] = useState('');
    const [editInputType, setEditInputType] = useState('Text');
    const [editInputScreen, setEditInputScreen] = useState('');
    const [editInputId, setEditInputId] = useState(null);
    const [editInputMandatory, setEditInputMandatory] = useState(false);
    const [types, setTypes] = useState([]);
    const [screens, setScreens] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [socialLoginOpen, setSocialLoginOpen] = useState(false);
    const [socialLogin, setSocialLogin] = useState();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [stripeFormOpen,setStripeFormOpen] = useState(false);
    const [stripePublic,setStripePublic] = useState('');
    const [message,setMessage] = useState('');
    const [stripe,setStripe] = useState('');




  const ITEMS_PER_PAGE = 25;

  const loadDetails = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    const profile = await cookies.get('profile');

    if(!token){
      navigate('/');
    }else{
      setToken(token);

      const code = searchParams.get('code');

      if(code && code.length > 0){
        //Send code to backend
        let socialRes = await setSocialToken({token,profile,code:code.replace('#',''),social:'piToken',tokenSent:'ABC'});

        if(!socialRes.data || !socialRes.data.success){
          alert("Error logging you into your social media account.");
          setMessage('Please try again.');
        }else{
          setMessage('Login Successful.');
        }
        setLoading(false);
      }


    }

  }

  useEffect(()=>{
    loadDetails();
  },[]);


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={32} color={"#191919"} />
        </div>
      </div>
    )
  }


  if(token){
    return(
      <div className="global-container">
        <div style={{fontFamily: "Montserrat",fontSize:"1.6rem",position:"relative",left:"10%",textAlign:"center",width:"80vw", height:"46%", marginTop:"30vh"}}>
          <img style={{marginBottom:"6vh",width:"12vw",borderRadius:"200px"}} src={(message == 'Login Successful.')? require('../../images/check-icon.png'):require('../../images/x-icon.png')}/>
          <br/>
          {message}
        </div>
      </div>
    )
}


}
