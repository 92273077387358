import getPaymentStatus from './getPaymentStatus';

const filterBookings = (bookings,filters)=>{
  let filteredArray = bookings

  if(filters.paymentStatusFilter && filters.paymentStatusFilter !== "All") filteredArray = filteredArray.filter((booking)=>{ return getPaymentStatus(booking) === filters.paymentStatusFilter})
  if(filters.filterStartDate) filteredArray = filteredArray.filter((booking)=>{ return (booking['m5_timeslot']?.startTime || booking.startTime) > filters.filterStartDate })
  if(filters.filterEndDate) filteredArray = filteredArray.filter((booking)=>{ return (booking['m5_timeslot']?.startTime || booking.startTime) < filters.filterEndDate })

  return filteredArray
}

export default filterBookings
